.app__video {
  height: 100vh;
  position: relative;
}

.app__video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.app__video-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.65);
}

.app__video-overlay_circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid var(--color--golden);
  cursor: pointer;
}

@media screen and (max-width: 2000px) {
  .app__video {
    width: 100%;
    position: relative;
  }
}

@media screen and (max-width: 910px) {
  .app__video-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 650px) {
  .app__video {
    height: 90%;
    width: 100%;
  }
}
