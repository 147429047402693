.app__driver-content{
    display: flex;
    flex-direction: column;
    width: 100%;


    margin-top: 5rem;
}

.driver{
    border-radius: 2%;
}

.app__driver-content_quote{
    display: flex;
    justify-content: flex-start;
    align-items: end;
}

.app__driver-content_quote img {
    width: 47px;
    height: 40px;
    margin: 0 1rem 1rem 0;
}

.app__driver-sign{
    width: 100%;
    margin-top: 3rem;
}


.app__driver-sign p:first-child{
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.04em;
    color: var(--color-golden);
}

@media screen and  (max-width: 2000px){
  .app__driver-sign {
  height: 200px;
}
}

@media screen and  (max-width: 450px){
     .driver{
        width: 70px;
     }
}

    

    
    
